<template>
  <!-- 无数据的时候 -->
  <div class="no_data" style="margin-top:50px;">
    <img src="@image/me/money_nonedata.png" alt="" />
    <p>{{ text }}</p>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      default: "暂无明细",
      type: String,
    },
  },
  name: "",
  data: () => ({}),
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
.no_data {
  img {
    width: rem(200);
    height: rem(200);
    display: block;
    margin: 0 auto;
  }
  p {
    height: rem(26);
    line-height: rem(26);
    text-align: center;
    font-weight: 500;
    color: #808080;
    margin-top: rem(32);
    font-size: rem(28);
  }
}
</style>
