<template>
  <div id="coupon">
    <no-data v-if="couponData.length == 0"></no-data>

    <van-list
      v-else
      error-text="请求失败，点击重新加载"
      v-model="loading"
      :finished="finished"
      finished-text="已无更多明细"
      @load="onLoad"
    >
      <div class="coupon_ul">
        <template v-for="(item, index) in couponData">
          <div class="coupon_li" v-if="item.goods_ids">
            <!-- 指定产品的 -->
            <div class="other flex-container">
              <div class="data flex1">
                <h1>{{ item.vouchertemplate_title }}</h1>

                <div class="product_ul flex-container">
                  <div
                    @click="toPath(items.gid)"
                    class="product_li"
                    v-for="items in item.goods_info"
                  >
                    <img :src="items.image" alt="" />
                    <div class="info flex-container">
                      <i>￥</i>
                      <template
                        v-if="item.vouchertemplate_limit < items.gprice"
                      >
                        <b>{{ items.gprice - item.vouchertemplate_price }}</b>
                        <span>券后价</span>
                      </template>

                      <template v-else>
                        <b>{{ items.gprice }}</b>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="btn flex-container">
                <div class="price">
                  <i>￥</i>
                  <b>{{ item.vouchertemplate_price }}</b>
                </div>
                <span class="tips">满{{ item.vouchertemplate_limit }}可用</span>
                <!-- <div class="time">
                    <span>距开抢</span>
                    <i>00</i>
                    <b>:</b>
                    <i>30</i>
                    <b>:</b>
                    <i>18</i>
                  </div> -->

                <template v-if="item.vouchertemplate_used == 0">
                  <div
                    class="btn_ no"
                    @click="receiveVoucher(item.vouchertemplate_id, index)"
                  >
                    领取
                  </div>
                  <span
                    style="
                      color: #808080;
                      text-align: center;
                      font-size: 8px;
                      margin-top: 0.4rem;
                    "
                    >限{{ item.vouchertemplate_storename }}可用</span
                  >
                </template>

                <template v-else>
                  <div
                    class="btn_ yes"
                    @click="$router.push({ name: 'HomeIndex' })"
                  >
                    去使用
                  </div>
                  <span
                    style="
                      color: #808080;
                      text-align: center;
                      font-size: 8px;
                      margin-top: 0.4rem;
                    "
                    >限{{ item.vouchertemplate_storename }}可用</span
                  >
                </template>
              </div>

              <img class="jtop" src="@image/me/coupon_jtop.png" alt="" />
              <img class="jbottom" src="@image/me/coupon_jbottom.png" alt="" />
            </div>
          </div>
          <div class="coupon_li" v-else>
            <!-- 全场的 -->
            <div class="all flex-container">
              <img
                @click="$router.push({ name: 'HomeIndex' })"
                src="@image/me/conpon_all.png"
                class="icon"
              />
              <div
                @click="$router.push({ name: 'HomeIndex' })"
                class="data flex1"
              >
                <h1>【通用券】{{ item.vouchertemplate_title }}</h1>
                <div class="info">
                  <i>￥</i>
                  <b>{{ item.vouchertemplate_price }}</b>
                  <span>满{{ item.vouchertemplate_limit }}可用</span>
                </div>
              </div>

              <template v-if="item.vouchertemplate_used == 0">
                <div class="btn">
                  <p
                    class="p"
                    @click="receiveVoucher(item.vouchertemplate_id, index)"
                  >
                    领取
                  </p>
                  <span
                    style="
                      color: #808080;
                      text-align: center;
                      font-size: 8px;
                      margin-top: 0.4rem;
                    "
                    >限{{ item.vouchertemplate_storename }}可用</span
                  >
                </div>
              </template>
              <template v-else>
                <div class="btn">
                  <p class="p1" @click="$router.push({ name: 'HomeIndex' })">
                    去使用
                  </p>
                  <span
                    style="
                      color: #808080;
                      text-align: center;
                      font-size: 8px;
                      margin-top: 0.4rem;
                    "
                    >限{{ item.vouchertemplate_storename }}可用</span
                  >
                </div>
              </template>

              <img class="jtop" src="@image/me/coupon_jtop.png" alt="" />
              <img class="jbottom" src="@image/me/coupon_jbottom.png" alt="" />
            </div>
          </div>
        </template>
      </div>
    </van-list>
  </div>
</template>
<script>
import { List, Toast } from "vant";
import noData from "@/components/noData/";
import { post } from "@get/http";
import { getReceiveVoucher } from "./js/";

export default {
  components: {
    noData,
    vanList: List,
  },
  data: () => ({
    loading: false,
    finished: false,
    couponData: [],
  }),
  created() {
    //do something after creating vue instance
    this.onLoad();
  },
  methods: {
    async receiveVoucher(tid, index) {
      await getReceiveVoucher(tid);
      this.couponData[index].vouchertemplate_used = 1;
    },
    toPath(goods_id) {
      this.$router.push({
        name: "HomeGoodsdetail",
        query: {
          goods_id,
        },
      });
    },
    async onLoad() {
      this.loading = true;
      let { voucher_list } = await post("/Voucher/voucher_tpl_list", {
        data: {
          store_id: this.$route.query?.store_id || 2,
          gettype: 2,
          is_show: 0, //0是显示  1是隐藏
        },
      });
      if (voucher_list) {
        if (voucher_list.length > 0) {
          for (var i = 0; i < voucher_list.length; i++) {
            if (voucher_list[i].goods_info.length > 0) {
              voucher_list[i].goods_info = JSON.parse(
                voucher_list[i].goods_info
              );
            }
          }
        }
      }
      if (!voucher_list) {
        voucher_list = [];
      }
      this.couponData.push(...voucher_list);
      this.loading = true;
      this.finished = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
#coupon {
  height: 100%;
  background: #ededed;
}
.all,
.other {
  background: #fff;
  width: rem(710);
  height: rem(240);
  margin: 0 auto;
  position: relative;
  border-radius: rem(10) rem(10) rem(10) rem(10);

  .jbottom,
  .jtop {
    z-index: 9999;
    width: rem(17);
    height: rem(8);
    position: absolute;
    left: rem(483);
  }
  .jbottom {
    bottom: 0;
  }
  .jtop {
    top: 0;
  }
  .btn {
    flex: 0 0 rem(220);
    height: rem(240);
    background: linear-gradient(90deg, #ffe6e6 0%, #fff0f0 100%);
    border-radius: 0px rem(10) rem(10) 0;
    position: relative;
  }
}
.coupon_ul {
  .coupon_li {
    padding-top: rem(28);
    .all {
      .btn {
        p {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          position: absolute;
          margin: auto;
          text-align: center;
          font-size: rem(26);
          font-weight: bold;
          width: rem(140);

          line-height: rem(54);
          border-radius: rem(27);
          height: rem(54);
        }
        span {
          display: block;
          margin-top: rem(200) !important;
        }
        .p {
          color: #fff;

          background: linear-gradient(270deg, #ff5533 0%, #ff853e 100%);
          box-shadow: 1px rem(4) rem(8) 0px rgba(255, 72, 72, 0.2);
        }
        .p1 {
          color: #ff4848;
          border: 1px solid #ff4848;
        }
      }
      // justify-content: center;
      // align-items: center;

      .icon {
        display: block;
        width: rem(164);
        height: rem(164);
        border-radius: rem(8);
        padding-top: rem(38);
        padding-left: rem(38);
      }
      .data {
        position: relative;
        padding-top: rem(38);
        h1 {
          // width: 206px;

          font-size: rem(26);
          color: #343434;
          height: rem(70);
          margin-top: rem(8);
          margin-bottom: rem(10);
          padding: 0 rem(28);
          @include ell2;
        }
        .info {
          padding-left: rem(28);
          color: #ff4848;
          i {
            font-size: rem(30);
            font-weight: bold;
          }
          b {
            font-size: rem(64);
            font-weight: bold;
          }
          span {
            font-size: rem(20);
            color: #808080;
          }
        }
      }
    }

    .other {
      h1 {
        font-size: rem(26);
        font-weight: bold;
        color: #343434;
        width: rem(400);
        @include ell;
        margin-left: rem(38);
        height: rem(70);
        line-height: rem(70);
      }
      .product_ul {
        display: -webkit-box;
        overflow-x: auto;
        /*适应苹果*/
        -webkit-overflow-scrolling: touch;
        width: rem(490);
        .product_li {
          margin: 0 rem(10);

          &:first-of-type {
            margin-left: rem(38);
          }
          &:last-of-type {
            padding-right: rem(38);
          }
          img {
            display: block;
            width: rem(124);
            height: rem(124);
            border-radius: rem(4);
            margin-bottom: rem(4);
          }
          .info {
            color: #ff4848;
            align-items: center;
            margin-top: rem(10);
            i {
              font-size: rem(18);
              font-weight: bold;
            }
            b {
              font-size: rem(22);
              font-weight: bold;
            }
            span {
              margin-left: rem(6);
              font-size: rem(16);
              font-size: rem(16);
              color: #ffffff;
              display: inline-block;
              padding: 0 rem(6);
              height: rem(20);
              background: #ff4848;
              border-radius: rem(10) rem(10) rem(10) 0px;
            }
          }
        }
      }
      .btn {
        flex-direction: column;

        justify-content: center;
        .price {
          color: #ff4747;
          text-align: center;
          height: rem(60);
          margin-bottom: rem(10);
          i {
            font-size: rem(30);
            font-weight: bold;
          }
          b {
            font-size: rem(64);
            font-weight: bold;
          }
        }
        .tips {
          display: block;
          color: #808080;
          font-size: rem(20);
          text-align: center;
          margin-bottom: rem(9);
        }
        .time {
          text-align: center;
          margin-bottom: rem(14);
          span {
            color: #333333;
            font-size: rem(20);
          }
          i {
            display: inline-block;
            padding: 0 rem(4);
            margin: 0 rem(6);
            height: rem(30);
            line-height: rem(30);
            background: #343434;
            border-radius: rem(4);
            font-size: rem(20);
            font-weight: bold;
            color: #ffffff;
            line-height: rem(30);
          }
          b {
            font-size: rem(26);
            color: #343434;
          }
        }
        .btn_ {
          margin: 0 auto;
          width: rem(140);

          font-size: rem(26);
          font-weight: bold;
          text-align: center;
          line-height: rem(54);
          height: rem(54);
          border-radius: rem(27);
        }
        .no {
          background: linear-gradient(270deg, #ff5533 0%, #ff853e 100%);
          box-shadow: 1px rem(4) rem(8) 0px rgba(255, 72, 72, 0.2);
          color: #fff;
        }
        .yes {
          color: #ff4848;
          border: 1px solid #ff4848;
        }
      }
    }
  }
}
</style>
